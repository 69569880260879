import React from 'react'
import { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import '../styles/navBarTop-styles.css'
import '../styles/navBar-styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row } from 'react-bootstrap'
import Logo from '../images/financial-aid-logo-reversed.svg'
import LogoMaroon from '../images/financial-aid-logo-maroon.svg'
import Navbar from '../components/navBar'

export default function NavbarTop({ location, navbar }) {

    const data = useStaticQuery(graphql`
        query HeaderQuery {
            site {
                siteMetadata {
                    menuLinks {
                        pagePath
                        name
                        fALevel1 {
                            pagePath
                            name
                            fALevel2 {
                                pagePath
                                name
                                fALevel3 {
                                    pagePath
                                    name
                                    # fALevel4 {
                                    #     pagePath
                                    #     name
                                    # }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    // Hook
    function useLocalStorage(key, initialValue) {

        const isBrowser = () => typeof window !== "undefined"
        // State to store our value
        // Pass initial state function to useState so logic is only executed once
        const [storedValue, setStoredValue] = useState(() => {
            if (isBrowser() && typeof window.location.pathname === "/") {
                return initialValue;
            }

            try {
                // Get from local storage by key
                const item = window.localStorage.getItem(key);
                // Parse stored json or if none return initialValue
                return item ? JSON.parse(item) : initialValue;
            } catch (error) {
                // If error also return initialValue
                console.log(error);
                return initialValue;
            }
        });

        // Return a wrapped version of useState's setter function that ...
        // ... persists the new value to localStorage.
        const setValue = (value) => {

            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore =
                    value instanceof Function ? value(storedValue) : value;
                // Save state
                setStoredValue(valueToStore);
                // Save to local storage
                if (isBrowser() && typeof window.location.pathname !== "undefined") {
                    window.localStorage.setItem(key, JSON.stringify(valueToStore));
                }
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error);
            }
        };

        return [storedValue, setValue];
    }

    // const [area, setArea] = useState();
    const [name, setName] = useLocalStorage("pageplace", "unset")
    const [secondary, setSecondary] = useState();
    const [isActive, setActive] = useState();
    const [navurl, setNavUrl] = useState(null);
    const isBrowser = () => typeof window !== "undefined"
    const pathName = isBrowser() && window.location.pathname;

    useEffect(() => {
        setNavUrl(window.location.pathname);
        let secondaryPath = window.location.pathname.replace(/^\/([^\/]*).([^\/]*).*$/, '$2');
        let shortPath = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
        setName(shortPath);
        setSecondary(secondaryPath);
    }, [(isBrowser() && window.location.pathname)]);

    return (
        <div className={`navbar__combo ${navbar}`} >
            <section className={`navbarTop ${location}`}>
                <Container>
                    <Row>
                        <header className="header__navbarTop">
                            <Link className="navbarTop__logo" to="/" onClick={() => setName('home')}><img src={Logo} alt="Financial Aid Logo Home Link" /></Link>
                            <Link className="navbarTop__logo_maroon" to="/" onClick={() => setName('home')}><img src={LogoMaroon} alt="Financial Aid Logo Home Link" /></Link>
                            <ul>
                                {data.site.siteMetadata.menuLinks.map((nav) => (

                                    <li>
                                        {(nav.name !== "My.UChicago") ? (
                                            <Link
                                                activeClassName='active'
                                                to={nav.pagePath}
                                                className={name === nav.name ? 'active__area' : 'null'}>
                                                {nav.name}</Link>
                                        ) : (

                                            <a href={nav.pagePath} target="_blank">
                                                {nav.name}
                                            </a>
                                            )}
                                            </li>
                                        
                                )) }
                            </ul>
                        </header>
                    </Row>
                </Container>
            </section>
            {/* NAVBAR INSERT BELOW */}

            <Navbar name={name} navurl={navurl} secondary={secondary} />
        </div>
    )
}
